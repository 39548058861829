import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setOpenModal } from '../../redux/reducers/appReducer'
import { setOrderForView } from '../../redux/reducers/ordersReducer'

import { cancelTaskToServer, completeTaskToServer, Tasks } from '../../redux/reducers/tasksReducer'
import ModalConfirm from '../ModalWrapper/ModalConfirm/ModalConfirm'

import style from './taskCard.module.scss'
import { Loader } from '../Loader/Loader'

const TaskCard: React.FC<Tasks> = (props) => {
  const [isModalConfirm, setIsModalConfirm] = useState(false)
  const [isModalCancelTask, setIsModalCancelTask] = useState(false)
  const [isDisableButton, setIsDisableButton] = useState(false)
  const dispatch = useDispatch()
  const {
    id, summ, order: { code, production_date_to, is_asap }
  } = props

  // Открыть модалку для подтверждения завершения задачи
  const openConfirmModal = () => {
    setIsModalConfirm(true)
  }
  const completeTask = () => {
    if (isDisableButton) {
      return
    }
    setIsDisableButton(true)
    dispatch(completeTaskToServer(id, { status: 'finish' }, () => setIsDisableButton(false)))
    setIsModalConfirm(false)
  }

  // Отменить задачу
  const openCancelModal = () => {
    setIsModalCancelTask(true)
  }
  const cancelTask = () => {
    dispatch(cancelTaskToServer({ id: id }))
    setIsModalCancelTask(false)
  }

  // Показать краткую инфо о заказе
  const showOrder = () => {
    dispatch(setOrderForView(props.order))
    dispatch(setOpenModal(true))
  }

  return (
    <div className={ `${ style.taskCard } ${ is_asap ? style.priority : '' }` }>
      <h2 className={ style.title }>{ code }</h2>

      <div className={ style.taskInfo }>
        <div className={ style.info }>
          <span className={ style.tableHead }>Информация</span>
          <span className={ style.tableValue }>
            <button onClick={ showOrder }
                    className={ style.buttonView }>
              Смотреть
            </button>
          </span>
        </div>

        <div className={ style.info }>
          <span className={ style.tableHead }>Стоимость</span>
          <span className={ style.tableValue }>{ summ }</span>
        </div>

        <div className={ style.info }>
          <span className={ style.tableHead }>Дата изготовления</span>
          <span className={ style.tableValue }>{ production_date_to }</span>
        </div>

      </div>

      <div className={ style.buttons }>
        <button className={ style.buttonEnd }
                onClick={ openConfirmModal }
                disabled={ isDisableButton }>
          {
            isDisableButton
              ? <Loader width={50} height={50}/>
              : 'Завершить'
          }
        </button>
        <button className={ style.buttonCancel }
                onClick={ openCancelModal }>Отменить
        </button>
      </div>

      {
        isModalConfirm &&
        <div className={ style.modalWrapper }>
          <ModalConfirm code={ code }
                        confirmCallback={ completeTask }
                        cancelCallback={ () => setIsModalConfirm(false) }
                        status={ 'SUCCESS' }/>
        </div>

      }
      {
        isModalCancelTask &&
        <div className={ style.modalWrapper }>
          <ModalConfirm code={ code }
                        confirmCallback={ cancelTask }
                        cancelCallback={ () => setIsModalCancelTask(false) }
                        status={ 'CANCEL' }/>
        </div>

      }
    </div>
  )
}

export default TaskCard